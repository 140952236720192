<template>
  <div id="startform_page" v-loading.fullscreen.lock="loading">
		<div class="container">
			<!-- Step one -->
			<div class="startform-step step-one" v-if="active_step == 1">
				<div class="start-icon-container">
					<div class="start-icon">
						<img :src="`${publicPath}img/learning-icon.svg`" alt="">
					</div>
				</div>

				<span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Sandyford mobility survey</span>
				<span style="display: block; text-align: center">This survey is part of publicly funded research into mobility and congestion in Sandyford, please help us make the district a better place to live and work!</span>
				<br>
				<span style="display: block; text-align: center">You will be entered into a €200 one4all coucher competition when you submit.</span>
				<br>
				<span style="display: block; text-align: center">Make sure to use you work email address to start. By continuing you agree to our <router-link to="privacy-policy">privacy policy</router-link>.</span>

				<div style="margin-top: 30px;">
					<form-item label="Full name">
						<el-input v-model="info.full_name" placeholder="Your full name"></el-input>
					</form-item>
				</div>

				<div style="margin-top: 15px;">
					<form-item label="Email">
						<el-input v-model="info.email" type="email" placeholder="Your work email address"></el-input>
					</form-item>
				</div>

			</div>

			<!-- Step two -->
			<div class="startform-step step-two" v-if="active_step == 2">

				<div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/testing-icon.svg`" alt="">
					</div>
				</div>

				<br>

				<div id="startform_location" class="startform-group">
					<span class="question-title">1. Where are you traveling from?</span>
					<!-- <span style="display: block">Your address is needed for area analytics and it won't be shared with anyone.</span> -->
					<el-input @change="validateAddress" :class="{w100: true, 'validate-success': info.address.validated}" style="margin-top: 8px" placeholder="Only visible by you" v-model="info.address.input_address">
						<el-button @click="validateAddress" type="primary" :icon="info.address.validated ? 'el-icon-check' : ''" slot="append">{{ info.address.validated ? 'Verified' : 'Verify' }}</el-button>
					</el-input>
				</div>

				

				<!-- <div style="display: none" id="startform_organization" class="startform-group">
					<span class="question-title">1. What location do you commute to?</span>

					<div v-for="(org_val, org_key) in company.organizations" :key="org_key">
						<div v-for="(loc_val, loc_id) in org_val.locations" :key="loc_id" class="surveyInputGroup">
							<input :id="loc_id" name="survey-location" type="radio" v-model="info.organization_location" :value="`${org_key}###${loc_id}`">
							<label :for="loc_id">{{ Object.keys(company.organizations).length > 1 ? `${org_val.name} - ${loc_val.name}` : loc_val.name }}</label>
						</div>
					</div>
				</div> -->

				<div id="startform_gotimes" class="startform-group">
					<span class="question-title">2. Your commute to work</span>
					<el-row style="margin-top: 8px" :gutter="20">
						<el-col :sm="11" style="margin-bottom: 10px">
							<el-time-select
								class="w100"
								placeholder="Time leaving home"
								v-model="info.commuting_times.go_leaving_time"
								:picker-options="{
									start: '05:30',
									step: '00:15',
									end: '15:00'
								}"></el-time-select>
						</el-col>
						<el-col :sm="13">
							<el-input class="w100" type="number" placeholder="Journey time" v-model="info.commuting_times.go_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</el-col>
					</el-row>		
				</div>

				<div id="startform_backtimes" class="startform-group">
					<span class="question-title">3. Your commute back home</span>
					<el-row style="margin-top: 8px" :gutter="20">
						<el-col :sm="11" style="margin-bottom: 10px">
							<el-time-select
								class="w100"
								placeholder="Time leaving office"
								v-model="info.commuting_times.back_leaving_time"
								:picker-options="{
									start: '12:00',
									step: '00:15',
									end: '23:45'
								}"></el-time-select>
						</el-col>
						<el-col :sm="13">
							<el-input class="w100" type="number" placeholder="Journey time" v-model="info.commuting_times.back_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</el-col>
					</el-row>
					
				</div>

				<div id="startform_transportmode" class="startform-group">
					<span class="question-title">4. How do you usually commute?</span>
					<el-select style="margin-top: 7px" placeholder="Select one" v-model="info.detault_transport_modes">
						<el-option v-for="opt in options" :key="opt.value" :label="opt.label" :value="opt.value"></el-option>
					</el-select>
				</div>

				<div id="startform_options" class="startform-group">
					<span class="question-title">5. What is your main reason for choosing that mode?</span>
					<el-input type="text" v-model="info.reason" placeholder="Reason"></el-input>
				</div>

				<div id="startform_parking" class="startform-group">
					<span class="question-title">6. Car parking</span>
					<!-- <span style="display: block">Please indicate what describes you best.</span> -->

					<div class="surveyInputGroup">
						<input id="dedicated-option-car-parking"  name="carparking_situation" v-model="info.carparking" type="radio" value="dedicated">
						<label for="dedicated-option-car-parking">I have a dedicated car parking space</label>
					</div>

					<div class="surveyInputGroup">
						<input id="easy-option-car-parking"  name="carparking_situation" v-model="info.carparking" type="radio" value="easy">
						<label for="easy-option-car-parking">I easily find parking</label>
					</div>

					<div class="surveyInputGroup">
						<input id="difficult-option-car-parking" name="carparking_situation" v-model="info.carparking" type="radio" value="difficult">
						<label for="difficult-option-car-parking">I have trouble parking</label>
					</div>

						<div class="surveyInputGroup">
						<input id="no_carparking-option-car-parking" name="carparking_situation" v-model="info.carparking" type="radio" value="no_carparking">
						<label for="no_carparking-option-car-parking">I don't drive</label>
					</div>

				</div>


				<div id="startform_options" class="startform-group">
					<span class="question-title">7. Do you occasionally use any other modes to travel to / from work? </span>
					<el-select style="margin-top: 7px" v-model="info.open_transport_modes" multiple placeholder="Select multiple">
						<el-option v-for="opt in options" :key="opt.value" :label="opt.label" :value="opt.value"></el-option>
					</el-select>
				</div>


				<div id="startform_company" class="startform-group">
					<span class="question-title">8. What company do you work for?</span>
					<el-select style="margin-top: 7px" v-model="info.company" placeholder="What's your company?">
						<el-option label="Microsoft" value="Microsoft"></el-option>
						<el-option label="Salesforce" value="Salesforce"></el-option>
						<el-option label="Vodafone" value="Vodafone"></el-option>
					</el-select>
				</div>

				<div id="startform_happiness" class="startform-group">
					<span class="question-title">9. On average, how happy are you with your commute?</span>
					<request-happiness-level v-model="info.happiness_level" style="margin-top: 10px"></request-happiness-level>
				</div>

				<div id="startform_options_alt" class="startform-group">
					<span class="question-title">10. Which alternative transport modes are you open to?</span>
					<el-select style="margin-top: 7px" v-model="info.open_transport_modes_2" multiple placeholder="Select multiple">
						<el-option v-for="opt in options" :key="opt.value" :label="opt.label" :value="opt.value"></el-option>
					</el-select>
				</div>

				<div id="startform_agegroup" class="startform-group">
					<span class="question-title">11. What's your age group?</span>

					<div class="surveyInputGroup">
						<input id="one-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="22">
						<label for="one-option-age-survey">Less than 26 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="two-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="30">
						<label for="two-option-age-survey">26 to 35 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="three-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="40">
						<label for="three-option-age-survey">36 to 45 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="four-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="50">
						<label for="four-option-age-survey">46 to 55 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="five-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="60">
						<label for="five-option-age-survey">56 to 65 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="six-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="70">
						<label for="six-option-age-survey">More than 65 years old</label>
					</div>

				</div>

				<div id="startform_gender" class="startform-group">
					<span class="question-title">12. M / F</span>
					<br>
					<input type="checkbox" id="gender_m"/>&nbsp;<label for="gender_m">M</label>
					<br>
					<input type="checkbox" id="gender_f"/>&nbsp;<label for="gender_f">F</label>
				</div>

				<div id="startform_company_1" class="startform-group">
					<span class="question-title">13. Would you be encouraged to have an active commute (walking, running or cycling) if the following work was undertaken in your organisation? </span>
					<el-select multiple style="margin-top: 7px" placeholder="Select one" v-model="info.option">
						<el-option label="Shower areas (provided / increased / improved)" value="Shower areas (provided / increased / improved)"></el-option>
						<el-option label="Lockers Provided" value="Lockers Provided"></el-option>
						<el-option label="Drying room for gear provided" value="Drying room for gear provided"></el-option>
						<el-option label="Cycle parking increased" value="Cycle parking increased"></el-option>
					</el-select>
				</div>		
			</div>

			<!-- Step three -->
			<div class="startform-step step-three" v-if="active_step == 3">
				<div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/email-check.png`" alt="">
					</div>
				</div>

				<span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Thanks, you're done!</span>
				<span style="display: block; text-align: center">We will send you an email to <b>{{ info.email }}</b> to verify your email to enter the draw of a €200 One4all gift card.</span>
				<br>
				

			</div>
		</div>

		<div v-if="button_text[active_step-1]" class="form-navigation-buttons">
			<div class="navigation-button">
				<el-button :disabled="loading" style="font-size: 17px" type="primary" class="secondary-color" @click="onSubmitForm" round>{{ button_text[active_step-1] }} &nbsp;<i class="el-icon-arrow-right el-icon-arrow-right"></i></el-button>
			</div>
		</div>
		
    
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import FormItem from '@/components/Form/FormItem';
import firebase from '@/firebase';
import RequestHappinessLevel from '@/components/RequestHappinessLevel';
import moment from 'moment';

export default {
	components: { PageTitle, FormItem, RequestHappinessLevel },
	data() {
		return {
			info: {
				reason: '',
				email: null,
				full_name: '',
				organization_location: null,
				carparking: null,
				address: {
					input_address: '',
					address_formatted: '',
					address_location: {},
					address_place_id: null,
					validated: false
				},
				company: null,
				frequent: {
					one: '',
					two: '',
					three: ''
				},
				happiness_level: null,
				default_transport_mode: null,
				age: null,
				gender: null,
				commuting_times: {
					go_leaving_time: null,
					go_commute_duration: null,
					back_leaving_time: null,
					back_commute_duration: null
				}
			},
			company: {
				organizations: {}
			},
			options: [
				{ value: 'walk', label: 'On foot' },
				{ value: 'cycle', label: 'Bicycle' },
				{ value: 'bus', label: 'Bus, minibus or coach' },
				{ value: 'luas', label: 'Luas' },
				{ value: 'train', label: 'Train or dart' },
				{ value: 'motorcycle', label: 'Motorcycle or moped' },
				{ value: 'escooter', label: 'Escooter' },
				{ value: 'drive', label: 'Driving a car' },
				{ value: 'carpooling.passenger', label: 'Passenger in a car with driver going to the same destination' },
				{ value: 'taxi.passenger', label: 'Passenger in a car with driver going to a different location' },
				{ value: 'taxi', label: 'Taxi' },
				{ value: 'van', label: 'Van' },
				{ value: 'other', label: 'Other means' },
				{ value: 'telecommute', label: 'Work mainly at or from home' },
			],
			submit_loading: false,
			active_step: 1,
			button_text: ['Start', 'Next', null],
			loading: false,
			publicPath: process.env.BASE_URL
		}
	},

	methods: {
		onSubmitForm() {
			if (this.active_step == 1) {
				return this.submitStepOne();
			} else if (this.active_step == 2) {
				return this.submitStepTwo();
			} else {
				return this.submitStepThree();
			}
		},

		// To submit step one
		async submitStepOne() {

			this.loading = true;
			setTimeout(() => { this.loading = false; this.active_step = 2;}, 1000);
			return;
			if (this.validateEmail(this.info.email.trim())) {
				// Sign up and if good, go to step 2
				this.loading = true;
				this.info.email = this.info.email.trim();

				let company = null;

				try {
					let _data = await this.SharoAPI({ action: 'getPublicCompany', data: { email_domain: this.info.email.replace(/.*@/, "").toLowerCase() }});

					if (_data.data.error) {
						this.loading = false;
						return this.$message({ type: 'error', showClose: true, message: _data.data.error_message });
					}

					company = _data.data.data;

				} catch (err) {
					this.loading = false;
					return this.$message({ type: 'error', showClose: true, message: 'An error occurred internally, please try again' });
				}

				// Set company
				this.company = company;

				firebase.auth().fetchSignInMethodsForEmail(this.info.email).then(result => {
					this.loading = false;

					// If the user already exists
					if (result.length > 0) {
						this.$message({ type: 'info', showClose: true, message: 'You already have an account with that email, please login instead'});
						this.$router.push({name: 'login', query: { email: this.info.email}});
					} else {
						this.active_step = 2;
					}
				});
				

			} else {
				this.$message({  message: 'Error, we need a valid email', showClose: true, type: 'error' });
			}
		},

		submitStepTwo() {
			let form_data = this.getPostingData();
			this.loading = true;

			// If not all data has been submitted
			// if (!form_data) {
			// 	this.loading = false;
			// 	return this.$message({ type: 'error', showClose: true, message: 'Please fill in all details and verify address' });
			// }

			setTimeout(_ => {	
				// Call API to update information
				// this.SharoAPI({ action: "createUserWithRegistrationInfo", data: form_data }).then(result => {
				// 	console.log(result)
				// 	if (result.data.error) {
				// 		return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
				// 	}
				// 	this.active_step = 3;
				// }).finally(_ => {
				// 	this.loading = false;
				// });
				this.active_step = 3;
				this.loading = false;
			}, 1000);
		},

		submitStepThree() {
			this.loading = true;

			setTimeout(_ => {
				this.loading = false;
				// this.$router.push('/');
			}, 600);
		},

		// Function to validate address
		async validateAddress() {
			let response = await this.geocode(this.info.address.input_address);
			console.log(response.results[0])
			
			if (response.results.length < 1) {
				this.info.address.validated = false;
				return this.$message({ type: 'error', showClose: true, message: 'Cannot find address, please try again'});
			}

			this.info.address.validated = true;
			this.info.address.input_address = response.results[0].formatted_address;
			this.info.address.address_formatted = response.results[0].formatted_address;
			this.info.address.address_location.lat = response.results[0].geometry.location.lat;
			this.info.address.address_location.lng = response.results[0].geometry.location.lng;
			this.info.address.address_place_id = response.results[0].place_id;
		},

		// Function to get all the data in a format to send to server
		getPostingData() {
			if (!(this.info.commuting_times.go_leaving_time 
			&& this.info.commuting_times.go_commute_duration
			&& this.info.commuting_times.back_leaving_time
			&& this.info.commuting_times.back_commute_duration
			&& this.info.default_transport_mode
			&& this.info.address.validated
			&& this.info.age
			&& this.info.gender
			&& this.info.happiness_level)) {
				return null;
			}

			// let _org = this.info.organization_location.split('###')[0];
			// let _office = this.info.organization_location.split('###')[1];

			// // If organization and office don't exist
			// if (!this.company.organizations[_org] || !this.company.organizations[_org].locations[_office]) {
			// 	return null;
			// }

			return true;

			// return {
			// 	first_name: this.info.full_name.split(' ')[0],
			// 	last_name: this.info.full_name.split(' ')[1] || '',
			// 	age: this.info.age,
			// 	commuting_times: {
			// 		go_leaving_time: moment.utc(this.info.commuting_times.go_leaving_time, 'HH:mm').toISOString(),
			// 		go_arriving_time: moment.utc(this.info.commuting_times.go_leaving_time, 'HH:mm').add(this.info.commuting_times.go_commute_duration, 'minutes').toISOString(),
			// 		back_leaving_time: moment.utc(this.info.commuting_times.back_leaving_time, 'HH:mm').toISOString(),
			// 		back_arriving_time: moment.utc(this.info.commuting_times.back_leaving_time, 'HH:mm').add(this.info.commuting_times.back_commute_duration, 'minutes').toISOString(),
			// 	},
			// 	gender: this.info.gender,
			// 	default_transport_mode: this.info.default_transport_mode,
			// 	initial_transport_mode: this.info.default_transport_mode,
			// 	initial_happiness_level: this.info.happiness_level,
			// 	happiness_level: this.info.happiness_level,
			// 	commuting_distance: this.calculateDistance(this.company.organizations[_org].locations[_office].location, this.info.address.address_location),
			// 	commuting_duration: Number(this.info.commuting_times.go_commute_duration),
			// 	address: {
			// 		address_formatted: this.info.address.address_formatted,
			// 		address_location: this.info.address.address_location,
			// 		address_place_id: this.info.address.address_place_id
			// 	},
			// 	// organization: this.info.organization_location.split('###')[0],
			// 	// office: this.info.organization_location.split('###')[1],
			// }
		}
	},

	mounted() {
		this.info.email  = this.$route.query.email || '';

		if (this.info.email) {
			let name_splits = this.info.email.split('@')[0].split('.');

			// If  format 'fname.lname@company.com'
			if (name_splits.length > 1) {
				this.info.full_name = name_splits.map(str => str.charAt(0).toUpperCase() + str.slice(1) ).join(' ');
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	@import 'start_form.scss';

	.el-col {
		margin-bottom: 0;
	}

	#startform_page {
		display: block;
		width: 100%;
		
	}

	.startform-step {
		width: 100%;
		margin-bottom: 90px;

		&.step-one, &.step-two, &.step-three {
			.start-icon-container {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 25px;

				.start-icon {
					width: 150px;
					height: 150px;
					padding: 20px;
					// border: 1px solid blue;
					background-color: #d1f1ff;
					border-radius: 50%;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	.form-navigation-buttons {
		z-index: 11000;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 0;
		padding: 10px 10px 20px 10px;
		width: 100%;
		background: linear-gradient(rgba(255, 255, 255, 0.5), white);

		.el-button {
			box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.1);
		}
	}
</style>