var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "startform_page" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm.active_step == 1
          ? _c("div", { staticClass: "startform-step step-one" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c("div", { staticClass: "start-icon" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.publicPath + "img/learning-icon.svg",
                      alt: ""
                    }
                  })
                ])
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Sandyford mobility survey")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "This survey is part of publicly funded research into mobility and congestion in Sandyford, please help us make the district a better place to live and work!"
                  )
                ]
              ),
              _c("br"),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "You will be entered into a €200 one4all coucher competition when you submit."
                  )
                ]
              ),
              _c("br"),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "Make sure to use you work email address to start. By continuing you agree to our "
                  ),
                  _c("router-link", { attrs: { to: "privacy-policy" } }, [
                    _vm._v("privacy policy")
                  ]),
                  _vm._v(".")
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Full name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Your full name" },
                        model: {
                          value: _vm.info.full_name,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "full_name", $$v)
                          },
                          expression: "info.full_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "email",
                          placeholder: "Your work email address"
                        },
                        model: {
                          value: _vm.info.email,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "email", $$v)
                          },
                          expression: "info.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 2
          ? _c("div", { staticClass: "startform-step step-two" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_location" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("1. Where are you traveling from?")
                  ]),
                  _c(
                    "el-input",
                    {
                      class: {
                        w100: true,
                        "validate-success": _vm.info.address.validated
                      },
                      staticStyle: { "margin-top": "8px" },
                      attrs: { placeholder: "Only visible by you" },
                      on: { change: _vm.validateAddress },
                      model: {
                        value: _vm.info.address.input_address,
                        callback: function($$v) {
                          _vm.$set(_vm.info.address, "input_address", $$v)
                        },
                        expression: "info.address.input_address"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "append",
                            type: "primary",
                            icon: _vm.info.address.validated
                              ? "el-icon-check"
                              : ""
                          },
                          on: { click: _vm.validateAddress },
                          slot: "append"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.info.address.validated ? "Verified" : "Verify"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_gotimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("2. Your commute to work")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving home",
                              "picker-options": {
                                start: "05:30",
                                step: "00:15",
                                end: "15:00"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.go_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "go_leaving_time",
                                  $$v
                                )
                              },
                              expression: "info.commuting_times.go_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Journey time"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times.go_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "go_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.go_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_backtimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("3. Your commute back home")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving office",
                              "picker-options": {
                                start: "12:00",
                                step: "00:15",
                                end: "23:45"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.back_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "back_leaving_time",
                                  $$v
                                )
                              },
                              expression:
                                "info.commuting_times.back_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Journey time"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times
                                    .back_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "back_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.back_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_transportmode" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("4. How do you usually commute?")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { placeholder: "Select one" },
                      model: {
                        value: _vm.info.detault_transport_modes,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "detault_transport_modes", $$v)
                        },
                        expression: "info.detault_transport_modes"
                      }
                    },
                    _vm._l(_vm.options, function(opt) {
                      return _c("el-option", {
                        key: opt.value,
                        attrs: { label: opt.label, value: opt.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_options" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "5. What is your main reason for choosing that mode?"
                    )
                  ]),
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "Reason" },
                    model: {
                      value: _vm.info.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "reason", $$v)
                      },
                      expression: "info.reason"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_parking" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("6. Car parking")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "dedicated-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "dedicated"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "dedicated")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "dedicated")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "dedicated-option-car-parking" } },
                      [_vm._v("I have a dedicated car parking space")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "easy-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "easy"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "easy")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "easy")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "easy-option-car-parking" } }, [
                      _vm._v("I easily find parking")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "difficult-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "difficult"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "difficult")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carparking", "difficult")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "difficult-option-car-parking" } },
                      [_vm._v("I have trouble parking")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carparking,
                          expression: "info.carparking"
                        }
                      ],
                      attrs: {
                        id: "no_carparking-option-car-parking",
                        name: "carparking_situation",
                        type: "radio",
                        value: "no_carparking"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carparking, "no_carparking")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.info,
                            "carparking",
                            "no_carparking"
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "no_carparking-option-car-parking" } },
                      [_vm._v("I don't drive")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_options" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "7. Do you occasionally use any other modes to travel to / from work? "
                    )
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { multiple: "", placeholder: "Select multiple" },
                      model: {
                        value: _vm.info.open_transport_modes,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "open_transport_modes", $$v)
                        },
                        expression: "info.open_transport_modes"
                      }
                    },
                    _vm._l(_vm.options, function(opt) {
                      return _c("el-option", {
                        key: opt.value,
                        attrs: { label: opt.label, value: opt.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_company" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("8. What company do you work for?")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { placeholder: "What's your company?" },
                      model: {
                        value: _vm.info.company,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "company", $$v)
                        },
                        expression: "info.company"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Microsoft", value: "Microsoft" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Salesforce", value: "Salesforce" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Vodafone", value: "Vodafone" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_happiness" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "9. On average, how happy are you with your commute?"
                    )
                  ]),
                  _c("request-happiness-level", {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.info.happiness_level,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "happiness_level", $$v)
                      },
                      expression: "info.happiness_level"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_options_alt" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "10. Which alternative transport modes are you open to?"
                    )
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { multiple: "", placeholder: "Select multiple" },
                      model: {
                        value: _vm.info.open_transport_modes_2,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "open_transport_modes_2", $$v)
                        },
                        expression: "info.open_transport_modes_2"
                      }
                    },
                    _vm._l(_vm.options, function(opt) {
                      return _c("el-option", {
                        key: opt.value,
                        attrs: { label: opt.label, value: opt.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_agegroup" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("11. What's your age group?")
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "one-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 22,
                        checked: _vm._q(_vm.info.age, 22)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 22)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "one-option-age-survey" } }, [
                      _vm._v("Less than 26 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "two-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 30,
                        checked: _vm._q(_vm.info.age, 30)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 30)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "two-option-age-survey" } }, [
                      _vm._v("26 to 35 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "three-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 40,
                        checked: _vm._q(_vm.info.age, 40)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 40)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "three-option-age-survey" } }, [
                      _vm._v("36 to 45 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "four-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 50,
                        checked: _vm._q(_vm.info.age, 50)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 50)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "four-option-age-survey" } }, [
                      _vm._v("46 to 55 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "five-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 60,
                        checked: _vm._q(_vm.info.age, 60)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 60)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "five-option-age-survey" } }, [
                      _vm._v("56 to 65 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "six-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 70,
                        checked: _vm._q(_vm.info.age, 70)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 70)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "six-option-age-survey" } }, [
                      _vm._v("More than 65 years old")
                    ])
                  ])
                ]
              ),
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_company_1" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v(
                      "13. Would you be encouraged to have an active commute (walking, running or cycling) if the following work was undertaken in your organisation? "
                    )
                  ]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "7px" },
                      attrs: { multiple: "", placeholder: "Select one" },
                      model: {
                        value: _vm.info.option,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "option", $$v)
                        },
                        expression: "info.option"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label:
                            "Shower areas (provided / increased / improved)",
                          value:
                            "Shower areas (provided / increased / improved)"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Lockers Provided",
                          value: "Lockers Provided"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Drying room for gear provided",
                          value: "Drying room for gear provided"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Cycle parking increased",
                          value: "Cycle parking increased"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 3
          ? _c("div", { staticClass: "startform-step step-three" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Thanks, you're done!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v("We will send you an email to "),
                  _c("b", [_vm._v(_vm._s(_vm.info.email))]),
                  _vm._v(
                    " to verify your email to enter the draw of a €200 One4all gift card."
                  )
                ]
              ),
              _c("br")
            ])
          : _vm._e()
      ]),
      _vm.button_text[_vm.active_step - 1]
        ? _c("div", { staticClass: "form-navigation-buttons" }, [
            _c(
              "div",
              { staticClass: "navigation-button" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "secondary-color",
                    staticStyle: { "font-size": "17px" },
                    attrs: {
                      disabled: _vm.loading,
                      type: "primary",
                      round: ""
                    },
                    on: { click: _vm.onSubmitForm }
                  },
                  [
                    _vm._v(_vm._s(_vm.button_text[_vm.active_step - 1]) + "  "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon-arrow-right"
                    })
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "startform-group", attrs: { id: "startform_gender" } },
      [
        _c("span", { staticClass: "question-title" }, [_vm._v("12. M / F")]),
        _c("br"),
        _c("input", { attrs: { type: "checkbox", id: "gender_m" } }),
        _vm._v(" "),
        _c("label", { attrs: { for: "gender_m" } }, [_vm._v("M")]),
        _c("br"),
        _c("input", { attrs: { type: "checkbox", id: "gender_f" } }),
        _vm._v(" "),
        _c("label", { attrs: { for: "gender_f" } }, [_vm._v("F")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }